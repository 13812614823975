import React from "react";
import Navigation from "./Main_Nav";
import { Typography } from "@mui/material";
import { ButtonContainer, TabsContainer } from "../components/styles/Container.Styled";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Button from "@mui/material/Button";
import ScrollToTop from "../components/ScrollToTop";
import { useNavigate } from "react-router-dom";

function Custom() {
  const navigate = useNavigate();
  return (
    <>
      <Navigation />
      <TabsContainer>
      <ScrollToTop />
        <Typography
        color="#EFF3F7"
          variant="h6"
          letterSpacing={1.7}
            fontFamily='OpenSans_Condensed-Regular'
          m={3}
          pt={10}
          id="padding-text"
          align="left"
        >
          <Typography
           letterSpacing={1.7}
           fontFamily='OpenSans_Condensed-Regular'
            variant="h3"
            pb={7}
            sx={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            Custom Web Design
          </Typography>
          
          <Typography
            letterSpacing={1.7}
            fontFamily='OpenSans_Condensed-Regular'
            variant="h5"
            pb={7}
            sx={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            <b>Responsive Web Design</b>
          </Typography>
          
          Responsive web design is an essential component in today's digital
          landscape. It goes beyond just improving mobile search rankings. One
          of its primary benefits is the ability to present a consistent brand
          image across all devices. In an era where web presence is crucial, it
          is imperative to provide positive user experiences regardless of the
          device being used.
          <br />
          <br />
          The adaptive nature of responsive web design allows your website
          layout to seamlessly adjust to screens of varying sizes. This
          flexibility ensures that your content is easily accessible and
          visually appealing, whether it's viewed on a large desktop monitor or
          a small smartphone screen. Moreover, responsive design is
          forward-compatible, meaning it can adapt to newer devices and higher
          resolutions as technology advances.
          <br />
          <br />
          Mobile-friendliness is often underestimated or overlooked during
          website creation or redesign. However, it should be a top priority.
          With the increasing number of users accessing the internet via mobile
          devices, having a responsive website is no longer just an optional
          feature, but rather a requirement. Neglecting mobile-friendliness can
          result in a poor user experience, high bounce rates, and ultimately, a
          negative impact on your website's performance.
          <br />
          <br />
          Furthermore, responsive web design directly influences your marketing
          strategies. A mobile-friendly website enhances your ability to engage
          with your target audience, as it allows users to easily navigate your
          site, view your content, and take desired actions. By providing a
          seamless user experience across devices, you can effectively capture
          and retain the attention of your visitors, leading to increased
          conversions and improved overall marketing results.
          <br />
          <br />
          <br />
          <Typography
            letterSpacing={1.7}
            fontFamily='OpenSans_Condensed-Regular'
            variant="h5"
            pb={7}
            sx={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            <b>Seamless User Experience</b>
          </Typography>
          Responsive web design is an essential investment that you shouldn't
          compromise on. It involves creating a website that can adapt to
          different devices, providing an optimal user experience. When
          implemented properly, responsive web design can last the lifetime of
          your website. Phlokk Design's responsive web design services go beyond
          simply changing the appearance of your website on various devices.
          They also focus on making your website mobile-friendly in all aspects,
          ensuring it loads quickly and functions seamlessly on mobile devices.
          <br />
          <br />
          One of the key areas where responsive design brings about significant
          changes is the website's content management system (CMS). The cost of
          responsive design projects can vary depending on factors such as the
          structure of your website, the extent of design changes required, and
          the graphical elements involved. These factors influence the overall
          cost, which can either increase or decrease accordingly.
          <br />
          <br />
          By investing in responsive web design, you are not only improving the
          user experience for your visitors but also future-proofing your
          website for emerging devices and technologies.
          <br />
          <br />
          <br />
          <Typography
            letterSpacing={1.7}
            fontFamily='OpenSans_Condensed-Regular'
            variant="h5"
            pb={7}
            sx={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            <b> Enhancing the Mobile Experience</b>
          </Typography>
          In the past, mobile-friendly design took a backseat, leading to clunky
          and frustrating website experiences for mobile users. However, as
          mobile browsing skyrocketed, the demand for mobile-friendly websites
          surged. This gave birth to responsive web design, a crucial factor not
          only for SEO but also for maintaining a consistent brand identity and
          capturing the expanding mobile user market.
          <br />
          <br />
          Today, mobile users expect a seamless viewing experience across all
          devices. Websites must adapt to mobile requirements in terms of layout
          and navigation, while still providing a familiar interface. By
          employing responsive web design, websites can effortlessly adjust to
          different screen sizes, ensuring menus and submenus remain in their
          expected locations. This design flexibility guarantees a smooth and
          enjoyable user journey.
          <br />
          <br />
          <ButtonContainer>
            <Button
              disableRipple
              variant="outlined"
              color="secondary"
              
              onClick={() => navigate("/contact")}
            >
              <EmailOutlinedIcon sx={{paddingRight: "5px"}}fontSize="small"/>Let's Talk
            </Button>
          </ButtonContainer>
        </Typography>
      </TabsContainer>
    </>
  );
}

export default Custom;
