import { useState } from "react";
import { TextField, Button, Typography, Box } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Navigation from "./Main_Nav";
import ScrollToTop from "../components/ScrollToTop";
import Alert from "@mui/material/Alert";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import bookLogo from "../images/book.svg";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

const options = [
  {
    value: "Application",
    label: "Application",
  },
  {
    value: "Branding",
    label: "Branding",
  },
  {
    value: "Support",
    label: "Support",
  },
  {
    value: "Graphics",
    label: "Graphics",
  },
  {
    value: "Server Issues",
    label: "Server",
  },
  {
    value: "Web",
    label: "Website",
  },
];

const referer = [
  {
    value: "Anthony Z",
    label: "Anthony Z - Florida",
  },
  {
    value: "Someone Else",
    label: "None of the above",
  },
];

export default function Contact() {
  const [captchaIsDone, setCaptchaDone] = useState(false);

  function onChange() {
    setCaptchaDone(true);
  }

  const url = "https://design-api.phlokkdesign.com/api/contact/userData";

  const resetForm = () => {
    setName("");
    setEmail("");
    setMessage("");
    setDepartment("");
    setReferral("");
  };

  const successMessage = () => {
    setSuccess(true);
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [department, setDepartment] = useState("");
  const [referral, setReferral] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !email || !message || !department || !referral) return;

    axios
      .post(url, {
        name,
        email,
        message,
        department,
        referral,
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (e) {
        console.log("Error is here axios call ====>", e);
      });

    resetForm();
    successMessage();
  };

  return (
    <>
      <Navigation />
      <ScrollToTop />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          paddingBottom: "100px",
          backgroundColor: "#eff3f7",
        }}
      >
        <Box sx={{ maxWidth: 600, p: 3 }}>
          <Typography align="center" paddingTop={10}>
            <img style={{ width: "125px" }} src={bookLogo} alt="logo" />
          </Typography>
          <Typography
            color="primary"
            lineHeight={1.6}
            fontFamily="Roboto"
            variant="h3"
            align="center"
            mb={1}
            pt={5}
          >
            Book a call
          </Typography>
          <Typography
            color="primary"
            letterSpacing={0.3}
            lineHeight={1.6}
            fontFamily="Roboto"
            variant="h5"
            align="center"
            pb={2}
          >
            <b>Request a free consultation</b>
          </Typography>
          <Typography
            color="primary"
            letterSpacing={1.3}
            fontFamily='OpenSans_Condensed-Regular'
            variant="h6"
            align="center"
            mb={2}
            pb={10}
          >
            Allow us to assist you in achieving your objectives through the implementation of a tailored, multi-channel marketing strategy specifically crafted for your brand.
          </Typography>

          <form onSubmit={handleSubmit}>
            <TextField
              color="primary"
              focused
              id="outlined-basic"
              variant="outlined"
              select
              label="Department"
              defaultValue="Web"
              helperText="Select department"
              value={department}
              onChange={(e) => setDepartment(e.target.value)}
              required
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              color="primary"
              focused
              id="outlined-basic"
              variant="outlined"
              sx={{
                marginLeft: 1,
              }}
              select
              label="Referral"
              defaultValue="referral"
              helperText="Who referred you?"
              value={referral}
              onChange={(e) => setReferral(e.target.value)}
              required
            >
              {referer.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              color="primary"
              focused
              helperText="Please enter your name"
              fullWidth
              label="Name"
              aria-label="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              margin="normal"
              required
              type="name"
            />
            <TextField
              color="primary"
              focused
              helperText="Please enter your email"
              fullWidth
              label="Email"
              aria-label="email"
              aria-required="true"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
              required
              type="email"
            />
            <TextField
              color="primary"
              id="outlined-basic"
              inputProps={{ style: { color: "#000" } }}
              focused
              helperText="Please enter your message"
              fullWidth
              label="Message"
              aria-label="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              margin="normal"
              required
              multiline
              rows={8}
              type="name"
            />
            <Typography pt={2} pb={2}>
              {success ? (
                <Alert
                  icon={<CheckCircleOutlineIcon fontSize="inherit" />}
                  severity="success"
                >
                  Your message has been received! A team member will get back to
                  you shortly. Thank you
                </Alert>
              ) : null}
            </Typography>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_SITE_KEY}
              onChange={onChange}
            />
            <Button
              disabled={!captchaIsDone}
              variant="contained"
              role="button"
              color="primary"
              type="submit"
              sx={{ mt: 2 }}
            >
              Submit
            </Button>
          </form>
        </Box>
      </Box>
    </>
  );
}
