import React from "react";
import Navigation from "./Main_Nav";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import {
  ButtonContainer,
  TabsContainer,
} from "../components/styles/Container.Styled";
import { useNavigate } from "react-router-dom";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ScrollToTop from "../components/ScrollToTop";
function Services() {
  const navigate = useNavigate();
  return (
    <>
      <Navigation />
      <TabsContainer>
      <ScrollToTop />
        <Typography
          color="#EFF3F7"
          variant="h6"
          letterSpacing={1.7}
          fontFamily='OpenSans_Condensed-Regular'
          m={3}
          pt={10}
          id="padding-text"
          align="left"
        >
          <Typography
            letterSpacing={1.7}
            fontFamily='OpenSans_Condensed-Regular'
            sx={{ textTransform: "uppercase", fontWeight: "bold" }}
            variant="h3"
            pb={7}
          >
            Services
          </Typography>
          
          <Typography
            letterSpacing={1.7}
            fontFamily='OpenSans_Condensed-Regular'
            variant="h5"
            pb={7}
            sx={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            Consulting
          </Typography>
         
          Introducing our human-centered web design methodology. When faced with
          the challenge of creating a compelling online presence, we believe in
          starting smart and simple. We break down complexity into a clear
          roadmap for website development, actively involving you in the
          process. Our methodology emphasizes faster iterations and maximum
          value. Our experts ensure that each step contributes to a
          transformative web design experience, guiding you through a seamless
          transition to new technology while leveraging your existing assets.
          <br />
          <br />
          Here's how our methodology works for web design:
          <br />
          <br />
          1. <b>Start smart:</b> Our expert team conducts in-depth research and
          planning to understand your business goals and target audience. We
          create a comprehensive roadmap for your website, ensuring it aligns
          with your brand identity and user expectations.
          <br />
          <br />
          2. <b>Design with impact:</b> <b>Phlokk Design</b> helps you create visually
          stunning and user-friendly websites that leave a lasting impression.
          We employ cutting-edge design techniques and intuitive navigation to
          enhance user engagement and drive conversions.
          <br />
          <br />
          3. <b>Develop for success:</b> Our experienced developers bring your
          website to life, focusing on performance, scalability, and security.
          We leverage the latest technologies and industry best practices to
          ensure your site is fast, reliable, and optimized for search engines.
          <br />
          <br />
          4. <b>Launch and optimize:</b> Once your website is ready, we assist
          with a seamless launch and provide ongoing support. We monitor user
          behavior, gather feedback, and make data-driven optimizations to
          continuously improve your website's performance and user experience.
          <br />
          <br />
          With our human-centered web design methodology, we aim to deliver a
          transformative online presence that captures your brand essence and
          drives business growth.
          <br />
          <br />
          <br />
        
          <Typography
            letterSpacing={1.7}
            fontFamily='OpenSans_Condensed-Regular'
            variant="h5"
            pb={7}
            sx={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            SEO
            </Typography>
            
          SEO requires meticulous planning, precise execution, and continuous
          refinement to yield tangible results. Over time, it has evolved into a
          multifaceted strategy with numerous moving parts, all carefully
          crafted by search engines. The challenge lies in developing the right
          SEO approach that consistently drives increased website traffic and
          attracts qualified leads. This is why SEO is often likened to a "long
          game" that demands persistence and strategic adaptation.
          <br />
          <br />
          If your current SEO strategy is falling short of your expectations,
          look no further than <b><i>Phlokk Design</i></b> for assistance. We understand the
          importance of ensuring your website doesn't get lost amid the sea of
          search results. Our team leverages data-driven insights to create
          scalable strategies that not only differentiate your brand from the
          competition but also deliver measurable return on investment (ROI). By
          partnering with us, you can expect a comprehensive plan tailored
          specifically to your brand's needs, designed to boost your online
          visibility and drive meaningful results.
          <br />
          <br />
          <br />
          <Typography
            letterSpacing={1.7}
            fontFamily='OpenSans_Condensed-Regular'
            variant="h5"
            pb={7}
            sx={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            <b>Branding</b>
          </Typography>
          Elevate Your Branding to New Heights At <b>Phlokk Design</b>, we are passionate about helping businesses like yours
          establish a powerful and unforgettable brand presence. As a leading
          branding expert company, we specialize in crafting tailored strategies
          that will set your brand apart from the competition.
          <br />
          <br />
          Why choose <b>Phlokk Design</b>?
          <br />
          <br />
          1. <b>Creative Excellence:</b> Our team of talented designers and branding
          experts are masters at creating visually stunning and impactful brand
          identities. We combine artistic flair with strategic thinking to
          deliver designs that resonate with your target audience and leave a
          lasting impression.
          <br />
          <br />
          2. <b>Customized Approach:</b> We understand that every brand is unique, and
          we take the time to understand your business, values, and goals. Our
          approach is tailored to your specific needs, ensuring that your brand
          reflects your identity and captures the essence of what makes you
          special.
          <br />
          <br />
          3. <b>Comprehensive Services:</b> From logo design and visual identity
          development to brand messaging and marketing collateral, we offer a
          comprehensive range of services to cover all aspects of your brand's
          visual representation. Our goal is to create a cohesive and consistent
          brand experience across all touch points.
          <br />
          <br />
          4. <b>Market Insights:</b> We conduct thorough market research and competitor
          analysis to gain valuable insights into your industry landscape. This
          allows us to identify trends, uncover opportunities, and position your
          brand strategically for maximum impact and market share.
          <br />
          <br />
          5. <b>Results-Driven:</b> We believe in delivering measurable results. Our
          branding strategies are data-informed and backed by analytics,
          allowing us to track the performance of our efforts and make
          data-driven optimizations along the way. Our goal is to help your
          brand thrive and achieve tangible business outcomes.
          <br />
          <br />
          6. <b>Collaborative Partnership:</b> We value collaboration and open
          communication with our clients. Your input and vision are integral to
          the branding process, and we work closely with you to ensure that your
          brand's personality shines through in every design element.
          <br />
          <br />
          Don't settle for an ordinary brand. Choose <b>Phlokk Design</b> as your
          branding expert company and unlock the true potential of your
          business. Let us help you create a brand that captivates, inspires,
          and drives success. Contact us today to embark on your brand elevation
          journey.
          <br />
          <br />
          <br />
          <Typography
            letterSpacing={1.7}
            fontFamily='OpenSans_Condensed-Regular'
            variant="h5"
            pb={7}
            sx={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            HOSTING
          </Typography>
          <b>Shared Hosting:</b> - Suitable for basic websites with low
          functionality needs. - Affordable hosting option for small businesses.
          - Provides essential tools for getting started. - Offers shared
          bandwidth, space, database, and FTP accounts. - Designed for low
          volume traffic. - Ensures security with 100% Uptime SLA and 24/7/365
          multilayer security access and monitoring.
          <br />
          <br />
          <b>Clustered Hosting:</b> - Ideal for more robust websites. - Combines
          multiple servers for enhanced performance. - Allows for easy
          scalability. - Suitable for medium sites with medium to high
          functionality requirements. - Offers allocated resources on demand. -
          Provides root access and scalability control. - Designed for high
          volume traffic. - Ensures security with 100% Uptime SLA and 24/7/365
          multilayer security access and monitoring.
          <br />
          <br />
          <ButtonContainer>
            <Button
              disableRipple
              variant="outlined"
              color="secondary"
              onClick={() => navigate("/contact")}
            >
              <EmailOutlinedIcon sx={{paddingRight: "5px"}}fontSize="small"/>Let's Talk
            </Button>
          </ButtonContainer>
        </Typography>
      </TabsContainer>
    </>
  );
}

export default Services;
