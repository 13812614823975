import { createGlobalStyle } from "styled-components";


const GlobalStyles = createGlobalStyle`

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');


* {
  box-sizing: border
}


body {
  margin: 0;
  background-color:#516790;
  font-family: 'Cormorant Garamond', sans-serif;
  font-size: 1.5em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


a {
  font-family: "Roboto";
  color: #EFF3F7;
}

a:hover {
  color: #d9d9da;
  transition: ease background-color 250ms;
}



`


export default GlobalStyles;