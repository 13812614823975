import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Sidebar from "./Sidebar";
import logo from "../images/phlokk-design-logo.svg";
import { motion, useScroll, useMotionValueEvent } from "framer-motion";

const navigation = [
  { name: "SERVICES", href: "/services" },
  { name: "WORK", href: "/work" },
  { name: "SOFTWARE", href: "/software" },
  { name: "CUSTOM", href: "/custom" },
  { name: "ABOUT", href: "/about" },
  { name: "CONTACT", href: "/contact" },
];

function Navigation() {
  const [sidebar, setSidebar] = useState(false);
  const { scrollY } = useScroll();

  const [hidden, setHidden] = useState(false);

  useMotionValueEvent(scrollY, "change", (latest) => {
    const previous = scrollY.getPrevious();
    if (latest > previous && latest > 150) {
      setHidden(true);
    } else {
      setHidden(false);
    }
  });

  return (
    <div className="navigation">
      <motion.nav
        variants={{
          visible: { y: 0 },
          hidden: { y: "-100%" },
        }}
        animate={hidden ? "hidden" : "visible"}
        transition={{ duration: 0.35, ease: "easeInOut" }}
        className="navbar navbar-expand nav-bg font-face-roboto"
      >
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img
              style={{ width: "300px" }}
              src={logo}
              className="site-logo"
              alt="logo"
            />
          </NavLink>

          <div className="navlinks_container navbar-nav ml-auto ">
            {navigation.map((item) => (
              <NavLink
                key={item.name}
                to={item.href}
                className={({ isActive }) => {
                  return (
                    "nav-link no-underline " +
                    (isActive ? "nav-link-active " : "nav-link")
                  );
                }}
              >
                {item.name}
              </NavLink>
            ))}
          </div>
          <div className="burger_icon" onClick={() => setSidebar(true)}>
            <MenuIcon sx={{ fontSize: 30 }} />
          </div>
        </div>
      </motion.nav>
      <Sidebar open={sidebar} onClose={() => setSidebar(false)} />
    </div>
  );
}

export default Navigation;
