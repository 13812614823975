import React from "react";
import { Typography, Button } from "@mui/material";
import {
  TitleBox,
  WebContainer
} from "./styles/Container.Styled";

function Referrals() {
  return (
    
    <WebContainer item xs={12} sm={4}>
              <TitleBox fontSize={24} p={2}>
                Referral Program
              </TitleBox>
              <Typography pt={3} letterSpacing={1.7} fontFamily='OpenSans_Condensed-Regular' fontSize={18}>
                <b>Earn 5% monthly recurring commissions.</b>
              </Typography>
              <Typography pt={3} letterSpacing={1.7} fontFamily='OpenSans_Condensed-Regular' fontSize={16}>
                For Subscriptions only
              </Typography>
              <Typography pt={5} pb={10}>
                <Button
                  disableRipple
                  href="https://phlokkdesign.com/contact"
                  color="secondary"
                  target="_blank"
                  variant="outlined"
                  role="button"
                  p={2}
                >
                  <b>Get Started</b>
                </Button>
              </Typography>
            </WebContainer>
  )
}

export default Referrals
