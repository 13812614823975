import React from "react";
import Navigation from "./Main_Nav";
import { Typography } from "@mui/material";
import { ButtonContainer, TabsContainer } from "./styles/Container.Styled";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ScrollToTop from "./ScrollToTop";
// Book a free consultation button on this page.

function About() {
  const navigate = useNavigate();
  return (
    <>
      <Navigation />
      <TabsContainer>
        <ScrollToTop />
        <Typography
          color="#EFF3F7"
          letterSpacing={1.7}
          fontFamily='OpenSans_Condensed-Regular'
          variant="h6"
          m={3}
          pt={10}
          id="padding-text"
          align="left"
        >
          <Typography
            letterSpacing={1.7}
            fontFamily='OpenSans_Condensed-Regular'
            variant="h3"
            pb={7}
            sx={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            About
          </Typography>
          <Typography
            letterSpacing={1.7}
            fontFamily='OpenSans_Condensed-Regular'
            variant="h4"
            pb={7}
          >
            <b>Phlokk Design</b> is a full service <span style={{color: "#20B1FE"}}><b>software design and digital agency</b></span> in Nashville, Tennessee
          </Typography>
          
          Phlokk Design, your premier web design partner, collaborates with a
          diverse range of businesses to craft engaging and interactive online
          experiences. With an extensive portfolio boasting a myriad of
          profoundly successful projects, our expertise is unparalleled, and our
          insights are enriched by enduring client partnerships spanning over
          two decades.
          <br />
          <br />
          At Phlokk Design, passion fuels our work. Committed to delivering
          excellence in web design, we specialize in creating dynamic online
          strategies that adapt to your evolving needs and seamlessly align with
          shifting business demands.
          <br />
          <br />
          Contact us today to discuss your web design needs and let us show you
          why we are the ideal partner for your online success. We promise to
          take care of you!
          <br />
          <br />
          <br />
          <ButtonContainer>
            <Button
              disableRipple
              variant="outlined"
              color="secondary"
              onClick={() => navigate("/contact")}
            >
              <EmailOutlinedIcon
                sx={{ paddingRight: "5px" }}
                fontSize="small"
              />
              Let's Talk
            </Button>
          </ButtonContainer>
        </Typography>
      </TabsContainer>
    </>
  );
}

export default About;
