import { Typography } from "@mui/material";
import React from "react";
import Navigation from "../Main_Nav";
import { LegalContainer } from "../styles/Container.Styled";
import ScrollToTop from "../../components/ScrollToTop";
function PrivacyPolicy() {
  return (
    <>
      <Navigation />
      <LegalContainer>
      <ScrollToTop />
        <Typography
          color=  "#EFF3F7"
          letterSpacing={0.3}
          lineHeight={1.6}
          variant="body1"
          fontFamily="Roboto"
          gutterBottom
          m={3}
          pt={10}
          pr={2}
          pl={2}
          align="left"
        >
          <Typography
          letterSpacing={0.3}
          lineHeight={1.6}
          variant="h4"
          pb={5}
          pt={10}
          fontFamily="Roboto">Privacy Policy
        </Typography>
          <b>Agreement between User and</b>{" "}
          <span className="termLinks">https://phlokkdesign.com</span>
          <br />
          <br />
          Protecting your private information is our priority. This Statement of
          Privacy applies to{" "}
          <span className="termLinks">https://phlokkdesign.com</span> and Phlokk
          Design and governs data collection and usage. For the purposes of this
          Privacy Policy, unless otherwise noted, all references to Phlokk
          Design include{" "}
          <span className="termLinks">https://phlokkdesign.com</span>. The
          Phlokk Design website is a News and information site. By using the
          Phlokk Design website, you consent to the data practices described in
          this statement.
          <br />
          <br />
          <b>Collection of your Personal Information</b>
          <br />
          In order to better provide you with products and services offered on
          our Site, Phlokk Design may collect personally identifiable
          information, such as your:
          <br />
          <br />
          First and Last Name
          <br />
          E-mail Address
          <br />
          Phone Number
          <br />
          <br />
          We do not collect any personal information about you unless you
          voluntarily provide it to us. However, you may be required to provide
          certain personal information to us when you elect to use certain
          products or services available on the Site. These may include: (a)
          registering for an account on our Site; (b) entering a sweepstakes or
          contest sponsored by us or one of our partners; (c) signing up for
          special offers from selected third parties; (d) sending us an email
          message; (e) submitting your credit card or other payment information
          when ordering and purchasing products and services on our Site. To
          wit, we will use your information for, but not limited to,
          communicating with you in relation to services and/or products you
          have requested from us. We also may gather additional personal or
          non-personal information in the future.
          <br />
          <br />
          <b>Use of your Personal Information</b>
          <br />
          Phlokk Design collects and uses your personal information to operate
          its website(s) and deliver the services you have requested.
          <br />
          <br />
          Phlokk Design may also use your personally identifiable information to
          inform you of other products or services available from Phlokk Design
          and its affiliates.
          <br />
          <br />
          <b>Sharing Information with Third Parties</b>
          <br />
          Phlokk Design does not sell, rent or lease its customer lists to third
          parties.
          <br />
          <br />
          Phlokk Design may share data with trusted partners to help perform
          statistical analysis, send you email or postal mail, provide customer
          support, or arrange for deliveries. All such third parties are
          prohibited from using your personal information except to provide
          these services to Phlokk Design, and they are required to maintain the
          confidentiality of your information.
          <br />
          <br />
          Phlokk Design may disclose your personal information, without notice,
          if required to do so by law or in the good faith belief that such
          action is necessary to: (a) conform to the edicts of the law or comply
          with legal process served on Phlokk Design or the site; (b) protect
          and defend the rights or property of Phlokk Design; and/or (c) act
          under exigent circumstances to protect the personal safety of users of
          Phlokk Design, or the public.
          <br />
          <br />
          <b>Tracking User Behavior</b>
          <br />
          Phlokk Design may keep track of the websites and pages our users visit
          within Phlokk Design, in order to determine what Phlokk Design
          services are the most popular. This data is used to deliver customized
          content and advertising within Phlokk Design to customers whose
          behavior indicates that they are interested in a particular subject
          area.
          <br />
          <br />
          <b>Automatically Collected Information</b>
          <br />
          Information about your computer hardware and software may be
          automatically collected by Phlokk Design. This information can
          include: your IP address, browser type, domain names, access times and
          referring website addresses. This information is used for the
          operation of the service, to maintain quality of the service, and to
          provide general statistics regarding use of the Phlokk Design website.
          <br />
          <br />
          <b>Links</b>
          <br />
          This website contains links to other sites. Please be aware that we
          are not responsible for the content or privacy practices of such other
          sites. We encourage our users to be aware when they leave our site and
          to read the privacy statements of any other site that collects
          personally identifiable information.
          <br />
          <br />
          <b>Right to Deletion</b>
          <br />
          Subject to certain exceptions set out below, on receipt of a
          verifiable request from you, we will:
          <br />
          <br />
          Delete your personal information from our records; and Direct any
          service providers to delete your personal information from their
          records. Please note that we may not be able to comply with requests
          to delete your personal information if it is necessary to:
          <br />
          <br />
          Complete the transaction for which the personal information was
          collected, fulfill the terms of a written warranty or product recall
          conducted in accordance with federal law, provide a good or service
          requested by you, or reasonably anticipated within the context of our
          ongoing business relationship with you, or otherwise perform a
          contract between you and us; Detect security incidents, protect
          against malicious, deceptive, fraudulent, or illegal activity; or
          prosecute those responsible for that activity; Debug to identify and
          repair errors that impair existing intended functionality; Exercise
          free speech, ensure the right of another consumer to exercise his or
          her right of free speech, or exercise another right provided for by
          law; Comply with the California Electronic Communications Privacy Act;
          Engage in public or peer-reviewed scientific, historical, or
          statistical research in the public interest that adheres to all other
          applicable ethics and privacy laws, when our deletion of the
          information is likely to render impossible or seriously impair the
          achievement of such research, provided we have obtained your informed
          consent; Enable solely internal uses that are reasonably aligned with
          your expectations based on your relationship with us; Comply with an
          existing legal obligation; or Otherwise use your personal information,
          internally, in a lawful manner that is compatible with the context in
          which you provided the information.
          <br />
          <br />
          <b>Children Under Thirteen</b>
          <br />
          Phlokk Design does not knowingly collect personally identifiable
          information from children under the age of thirteen. If you are under
          the age of thirteen, you must ask your parent or guardian for
          permission to use this website.
          <br />
          <br />
          <b>E-mail Communications</b>
          <br />
          From time to time, Phlokk Design may contact you via email for the
          purpose of providing announcements, promotional offers, alerts,
          confirmations, surveys, and/or other general communication.
          <br />
          <br />
          If you would like to stop receiving marketing or promotional
          communications via email from Phlokk Design, you may opt out of such
          communications by Clicking an unsubscribe button.
          <b>Changes to this Statement</b>
          <br />
          Phlokk Design reserves the right to change this Privacy Policy from
          time to time. We will notify you about significant changes in the way
          we treat personal information by sending a notice to the primary email
          address specified in your account, by placing a prominent notice on
          our site, and/or by updating any privacy information on this page.
          Your continued use of the Site and/or Services available through this
          Site after such modifications will constitute your: (a) acknowledgment
          of the modified Privacy Policy; and (b) agreement to abide and be
          bound by that Policy.
          <br />
          <br />
          <b>Contact Information</b>
          <br />
          Phlokk Design welcomes your questions or comments regarding this
          Statement of Privacy. If you believe that Phlokk Design has not
          adhered to this Statement, please contact Phlokk Design at:
          <br />
          <br />
          Phlokk Design
          <br />
          Nashville, Tennessee
          <br />
          <br />
          Email Address:
          <br />
          legal@phlokk.com
          <br />
          <br />
          Telephone number:
          <br />
          615-967-5790
          <br />
        </Typography>
      </LegalContainer>
    </>
  );
}

export default PrivacyPolicy;
