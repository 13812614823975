import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import paramount from "../images/logos/paramount-builders.png";
import studio from "../images/logos/reel-dreams-studio.png";
import recordLabel from "../images/logos/sonic-verse-records.png";
import eliteRoofing from "../images/logos/eliteRoofing.png";
import futurax from "../images/logos/futurax.png";
import coin from "../images/logos/atz.png";
import phlokk from "../images/logos/phlokk.com.png";
import phlokkApp from "../images/logos/phlokk-app.png";
import jay_jo_logo from "../images/logos/jay-jo-logo.png";
import moonlight from "../images/logos/moonlight-manor.png";
import Navigation from "./Main_Nav";
import ScrollToTop from "./ScrollToTop";

const sites = [
  {
    id: 1,
    title: "phlokk.com",
    href: "https://phlokk.com",
    src: phlokk,
    alt: "phlokk.com",
  },
  {
    id: 2,
    title: "Phlokk (application)",
    href: "https://phlokk.com",
    src: phlokkApp,
    alt: "phlokk.com",
  },
  {
    id: 3,
    title: "atz333.com",
    href: "https://atz333.com",
    src: coin,
    alt: "atz333.com",
  },
  {
    id: 4,
    title: "jayandjoshow.com",
    href: "https://jayandjoshow.com",
    src: jay_jo_logo,
    alt: "jayandjoshow.com",
  },
  {
    id: 5,
    title: "Paramount Builders",
    src: paramount,
    alt: "paramount builders",
  },
  {
    id: 6,
    title: "Elite Roofing",
    src: eliteRoofing,
    alt: "elite roofing",
  },
  { title: "Reel Dreams Studio", src: studio, alt: "Reel Dreams Studio" },
  {
    id: 7,
    title: "Sonic-Verse Records",
    src: recordLabel,
    alt: "Sonic-Verse Records",
  },
  { id: 8, title: "FuturaX (Logo)", src: futurax, alt: "FuturaX" },
  {
    id: 9,
    title: "Moonlight Manor",
    src: moonlight,
    alt: "Moonlight Manor",
  },
];

function Work() {
  return (
    <>
    <Navigation />
    <Box sx={{ flexGrow: 1, backgroundColor: "#000", }}>
    <ScrollToTop />

      <Grid container spacing={2}>
        {sites.map((site) => {
          return (
            <Grid lg={4} md={6} sm={12} xs={12}>
              <Box sx={{p: 10, mt: 10 }} >
                
              
              <Typography align="center">
                <a href={site.href} alt={site.alt}>
                <Typography
                  color="#EFF3F7;"
                  variant="h4"
                  align="center"
                  letterSpacing={1.6}
                  fontFamily="Roboto"
                >
                  {site.title}
                </Typography>
                
                </a>
                </Typography>
                </Box>
                <Box sx={{textAlign: "center" }} >
                <Typography>
                <a href={site.href} alt={site.alt}>
                  <img key={site.id} src={site.src} alt={site.alt} />
                </a>
                </Typography>
                </Box>
              
              
            </Grid>
          );
        })}
      </Grid>
      </Box>
    </>
  );
}

export default Work;
