import React from "react";
import { Link } from "react-router-dom";
import Navigation from "./Main_Nav";
import { Typography, Box } from "@mui/material";
import shipwrecked from "../components/lottie/shipwrecked.json";
import Lottie from "lottie-react";

function NotFound() {
  return (
    <>
      <Navigation />

      <Box sx={{ backgroundColor: "#000", textAlign: "center" }}>
        <Typography className="lottieDiv" pb={5} pt={16} align="center">
            <Lottie className="lottieShip" animationData={shipwrecked} loop={true} />
            </Typography>
        <Typography color="secondary" variant="h1" fontFamily={"Roboto"}>4 0 4</Typography>
          <Typography color="secondary" fontFamily={"Roboto"} pt={5} pb={5} variant="h4">Page does not exist!</Typography>
          <Typography pb={15} fontFamily={"Roboto"} color="secondary" variant="h5">Go back to<Link to="/"> <span className="homepageLink">Homepage</span></Link></Typography>
        </Box>

    </>
  );
}

export default NotFound;
