import React from "react";
import Navigation from "./Main_Nav";
import { Typography } from "@mui/material";
import {
  ButtonContainer,
  TabsContainer,
} from "./styles/Container.Styled";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ScrollToTop from "./ScrollToTop";

// Book a free consultation button on this page.

function Software() {
  const navigate = useNavigate();
  return (
    <>
      <Navigation />
      <TabsContainer>
        <ScrollToTop />
        <Typography
        color="#EFF3F7"
          letterSpacing={0.3}
          lineHeight={1.6}
          variant="h6"
          fontFamily='OpenSans_Condensed-Regular'
          m={3}
          pt={10}
          id="padding-text"
          align="left"
        >
          <Typography
            letterSpacing={1.7}
            fontFamily='OpenSans_Condensed-Regular'
            variant="h3"
            pb={7}
            sx={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            Application Development
          </Typography>
          
          Unleash the potential of mobile innovation to conquer tomorrow's
          business challenges. Our dynamic and collaborative teams empower you
          to deliver cutting-edge mobile applications that scale effortlessly
          and launch features at lightning speed. With expertise in React
          Native, we're equipped to revolutionize your mobile experience.
          <br /> <br />
          Our mastery of hybrid app development allows us to create versatile
          applications that seamlessly blend the best of native and web
          technologies. By harnessing this power, your app can effortlessly
          reach a wider audience across different platforms and devices. <br />{" "}
          <br />
          Take your customer experience to new heights by infusing your apps
          with high intelligence through hyper-personalization, modern tech
          practices, and seamless integration with existing systems. Connect
          with <b><i>Phlokk Design's</i></b> mobility team today to develop future-proof
          applications that constantly evolve to meet the ever-growing
          expectations of users.
          <br />
          <br />
          <br />
          <Typography
            letterSpacing={1.7}
            fontFamily='OpenSans_Condensed-Regular'
            variant="h5"
            pb={7}
            sx={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            <b>Cross-Platform App Development</b>
          </Typography>
          With our cross-platform app development services, we specialize in
          building high-quality mobile apps that seamlessly function across
          multiple operating systems. Our expertise and advanced technology
          enable us to strike a perfect balance between efficient development
          using a single codebase and delivering the performance and user
          experience of a native application. This approach ensures faster
          time-to-market for your app, allowing you to reach your target
          audience sooner. Our team of skilled developers leverages
          industry-leading frameworks and tools to create industry-grade mobile
          apps that not only meet your requirements but also provide an
          exceptional user experience. Trust us to deliver reliable, scalable,
          and visually appealing cross-platform apps that cater to the diverse
          needs of your users across different mobile platforms.
          <br />
          <br />
          <br />
          <Typography
            letterSpacing={1.7}
            fontFamily='OpenSans_Condensed-Regular'
            variant="h5"
            pb={7}
            sx={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            <b>Revolutionize Your Business</b>
          </Typography>
          Are you looking to revolutionize your business by creating innovative
          and groundbreaking products and applications? At our company, we
          specialize in harnessing cutting-edge technologies to bring your ideas
          to life. Our team of experts is dedicated to pushing the boundaries of
          what is possible, ensuring that your products and applications stand
          out from the competition. With our deep understanding of the latest
          advancements in technology, we can help you leverage emerging trends
          and tools to create truly transformative solutions. Whether it's
          developing immersive virtual reality experiences, implementing
          artificial intelligence algorithms, or integrating Internet of Things
          devices, we have the expertise to turn your vision into a reality.
          Trust us to be your partner in innovation and take your business to
          new heights.
          <br />
          <br />
          <br />
          <ButtonContainer>
            <Button
              disableRipple
              variant="outlined"
              color="secondary"

              onClick={() => navigate("/contact")}
            >
              <EmailOutlinedIcon sx={{paddingRight: "5px"}}fontSize="small"/>Let's Talk
            </Button>
          </ButtonContainer>
        </Typography>
      </TabsContainer>
    </>
  );
}

export default Software;
