import React from "react";
import Drawer from "@mui/material/Drawer";
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import logo from "../images/phlokk-design-logo.svg";
import { Typography } from "@mui/material";
const navItems = ["services", "work", "custom", "software", "about", "contact" ];




const Sidebar = ({ open, onClose }) => {

  
  const navigation = useNavigate();
  return (
    <Drawer
      anchor={"top"}
      open={open}
      onClose={onClose}
      transitionDuration={500}
      sx={{
        width: "100%",
        "& .MuiDrawer-paper": {
          width: "100%",
          backgroundColor: "#080066",
        },
      }}
    >
      <img src={logo} className="side-bar-logo" alt="phlokk-logo" onClick={() => navigation("/")}/>
     
      <CloseIcon onClick={onClose} className="sidebar_closeIcon" />
      <div className="sidebar_links_container">
        {navItems.map((item, i) => (
          <div
            className="sidebar_links"
            key={item}
            onClick={() => navigation(`/${item}`)}
          >
            <Typography textTransform="uppercase" lineHeight={1.7} letterSpacing={.9} color="#fff" fontSize="18px" fontFamily= 'OpenSans_Condensed-Regular' sx={{
                        "&:hover": {
                          cursor: "pointer",
                        },
                      }}><b>{item}</b></Typography>
                      
          </div>
        ))}
      </div>
    </Drawer>
  );
};

export default Sidebar;
