import * as React from "react";
import { Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { BoxContainer, FaqContainer } from "./styles/Container.Styled";
import question from "../components/lottie/question.json";
import Lottie from "lottie-react";

function Faq() {

  return (
    <BoxContainer>
      <FaqContainer>
      <Typography className="lottieDiv" pb={5} pt={16} align="center">
            <Lottie className="lottie" animationData={question} loop={true} />
            </Typography>
        <Typography
          color="#EFF3F7"
          variant="h3"
          align="center"
          letterSpacing={1.6}
          fontFamily="Roboto"
          pb={15}
          pt={5}
          
        >
          FAQs
        </Typography>
        <Accordion
          sx={{
            backgroundColor: "transparent",
            color: "#EFF3F7",
            paddingBottom: 5,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#EFF3F7" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontSize={20} letterSpacing={1.6} fontFamily='OpenSans_Condensed-Regular'>
              Why wouldn't I just hire a developer?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontSize={20} letterSpacing={1.6} fontFamily='OpenSans_Condensed-Regular'>
              The annual cost of a full-time senior-level designer is now over
              $100,000, plus benefits. Finding available designers can be
              challenging. Additionally, there may be times when you don't have
              enough work to keep them busy, resulting in paying for unused
              time.
              <br />
              <br />
              With the monthly plan, you have the flexibility to pause and
              resume your subscription as needed. This ensures that you only pay
              for your designer when you have work available for them.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            backgroundColor: "transparent",
            color: "#EFF3F7",
            paddingBottom: 5,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#EFF3F7" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontSize={20} letterSpacing={1.6} fontFamily='OpenSans_Condensed-Regular'>
              How fast is this service?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontSize={20} letterSpacing={1.6} fontFamily='OpenSans_Condensed-Regular'>
              On average, most requests are completed in just two days or less.
              However, more complex requests can take longer. App development is
              an ongoing process.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            backgroundColor: "transparent",
            color: "#EFF3F7",
            paddingBottom: 5,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#EFF3F7" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontSize={20} letterSpacing={1.6} fontFamily='OpenSans_Condensed-Regular'>
              Who designs my request?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontSize={20} letterSpacing={1.6} fontFamily='OpenSans_Condensed-Regular'>
              At Phlokk Design, we have a dedicated team of agency members who
              are prepared to handle requests on a daily basis. We request
              weekends off to ensure that we consistently deliver our best work.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            backgroundColor: "transparent",
            color: "#EFF3F7",
            paddingBottom: 5,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#EFF3F7" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontSize={20} letterSpacing={1.6} fontFamily='OpenSans_Condensed-Regular'>
              Can I pause my subscription?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontSize={20} letterSpacing={1.6} fontFamily='OpenSans_Condensed-Regular'>
              We recognize that you may not currently have a sufficient amount
              of design work to occupy an entire month. It's possible that you
              only have one or two design requests at the moment. In such cases,
              we offer the option to pause your subscription.
              <br />
              <br />
              Our billing cycles operate on a 31-day period. For instance, if
              you sign up and utilize our service for 21 days before deciding to
              pause your subscription, the billing cycle will be paused as well.
              As a result, you will have 10 days of service remaining, which can
              be utilized at any time in the future.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            backgroundColor: "transparent",
            color: "#EFF3F7",
            paddingBottom: 5,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#EFF3F7" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontSize={20} letterSpacing={1.6} fontFamily='OpenSans_Condensed-Regular'>
              How do I request your services?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontSize={20} letterSpacing={1.6} fontFamily='OpenSans_Condensed-Regular'>
              Phlokk Design provides clients with a high degree of flexibility
              in requesting designs through the use of Jira. Clients commonly
              request designs by directly utilizing Jira. Essentially, any
              content that can be linked or shared within Jira is acceptable
              for submission.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            backgroundColor: "transparent",
            color: "#EFF3F7",
            paddingBottom: 5,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#EFF3F7" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontSize={20} letterSpacing={1.6} fontFamily='OpenSans_Condensed-Regular'>
              What if I don't like the design?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontSize={20} letterSpacing={1.6} fontFamily='OpenSans_Condensed-Regular'>
              Rest assured! We provide unlimited revisions until each design is
              approved by you. This is our guarantee and a testament to our
              confidence in the quality of our service.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            backgroundColor: "transparent",
            color: "#EFF3F7",
            paddingBottom: 5,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#EFF3F7" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontSize={20} letterSpacing={1.6} fontFamily='OpenSans_Condensed-Regular'>
              What if I only have a single request?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontSize={20} letterSpacing={1.6} fontFamily='OpenSans_Condensed-Regular'>
              Once you have completed your design needs, you have the option to
              pause your subscription and resume it when you have additional
              design requirements. We want to ensure that you make the most of
              your subscription and avoid any unused time. After all, time is
              money.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          sx={{
            backgroundColor: "transparent",
            color: "#EFF3F7",
            paddingBottom: 5,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#EFF3F7" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontSize={20} letterSpacing={1.6} fontFamily='OpenSans_Condensed-Regular'>
              What is your refund policy?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography fontSize={20} letterSpacing={1.6} fontFamily='OpenSans_Condensed-Regular'>
              As a result of the exceptional quality of our work, there will be
              no refunds issued.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </FaqContainer>
    </BoxContainer>
  );
}
export default Faq;
