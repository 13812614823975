import { Typography, Box } from "@mui/material";
import React, { useRef, useEffect } from "react";
import Navigation from "./Main_Nav";

import {
  SalesContainer,
  HeroContainer,
  CostContainer,
  ExperienceContainer,
  BoxContainer,
  QuoteContainer,
  AnimateContainer,
  PlansContainer,
  CTAContainer,

} from "../components/styles/Container.Styled";
import logo from "../images/logo_phlokk.svg";
import quotes from "../images/quotes.svg";
import cta_pointer from "../images/cta_pointer.svg";
import rocket from "../components/lottie/rocket.json";
import ScrollToTop from "../components/ScrollToTop";
// import Promotions from "./Promotions";
import Lottie from "lottie-react";
import { motion, useInView, useAnimation } from "framer-motion";
import Categories from "./Categories";
import Plans from "./Plans";
import Faq from "./Faq";

// import Slider from "./Slider";

function Home() {
  const pricing = useRef(null);

  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  const firstRef = useRef(null);
  const ref = useRef(null);
  const newRef = useRef(null);

  const isInView3 = useInView(firstRef, { once: true });
  const isInView = useInView(ref, { once: true });
  const isInView2 = useInView(newRef, { once: true });

  const TypographyDiv = motion(Typography);

  const titleControls = useAnimation();
  const mainControls = useAnimation();
  const SubControls = useAnimation();

  useEffect(() => {
    if (isInView3) {
      titleControls.start("visible");
    }
  }, [isInView3, titleControls]);

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView, mainControls]);

  useEffect(() => {
    if (isInView2) {
      SubControls.start("visible");
    }
  }, [isInView2, SubControls]);

  return (
    <>
      <Navigation />
      <HeroContainer>
        <ScrollToTop />
        <Typography align="center" pt={3}>
          <img style={{ width: "75px" }} src={logo} alt="logo" />
        </Typography>
        <Typography
          color="#EFF3F7"
          variant="h3"
          align="center"
          letterSpacing={1.6}
          fontFamily="Roboto"
          p={5}
        >
          Phlokk Design
        </Typography>

        <SalesContainer ref={firstRef}>
          <TypographyDiv
            whileInView={{ opacity: 1 }}
            variants={{
              hidden: { opacity: 0, y: 40 },
              visible: { opacity: 1, y: 0 },
            }}
            transition={{ duration: 0.5, delay: 0.25 }}
            initial="hidden"
            animate={SubControls}
            color="#EFF3F7"
            variant="h2"
            align="center"
            aria-label="main"
            role="contentinfo"
            letterSpacing={1.6}
            fontFamily="Roboto"
            pb={10}
            id="padding-title"
          >
            "We didn't reinvent the wheel, we created a new paradigm"
          </TypographyDiv>
          <TypographyDiv
            whileInView={{ opacity: 1 }}
            variants={{
              hidden: { opacity: 0, y: 40 },
              visible: { opacity: 1, y: 0 },
            }}
            transition={{ duration: 0.5, delay: 0.25 }}
            initial="hidden"
            animate={SubControls}
            color="#EFF3F7"
            variant="h5"
            align="center"
            letterSpacing={1.6}
            fontFamily="Roboto"
            pb={10}
            id="padding-title"
          >
            Traditional design is a thing <br />
            of the past, embrace design you've always desired.
          </TypographyDiv>
        </SalesContainer>
        <PlansContainer>
          <div
            onClick={() => scrollToSection(pricing)}
            role="button"
            className="plansButton"
          >
            <div>SEE PLANS</div>
          </div>
        </PlansContainer>
      </HeroContainer>

      <ExperienceContainer>
        <Typography>
          <Typography
            color="#fff"
            variant="h4"
            align="center"
            letterSpacing={1.6}
            fontFamily="Roboto"
            pt={10}
            id="padding-title"
          >
            "Phlokk Design <br /> masterfully weaves subtlety <br /> into their
            creations,
            <br /> creating an immersive
            <br /> sensory experience."
          </Typography>
        </Typography>
      </ExperienceContainer>
      <Box>
        <div class="wave">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
              class="shape-fill"
            ></path>
          </svg>
        </div>
      </Box>

      <AnimateContainer ref={ref}>
        <TypographyDiv
          whileInView={{ opacity: 1 }}
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          transition={{ duration: 0.5, delay: 0.25 }}
          initial="hidden"
          animate={mainControls}
          color="secondary"
          variant="h2"
          align="center"
          letterSpacing={1.6}
          fontFamily="Roboto"
          pt={10}
          pb={10}
          pr={10}
          pl={10}
        >
          "Our process is <span className="seamless">seamless</span>"
        </TypographyDiv>

        <TypographyDiv
          whileInView={{ opacity: 1 }}
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          transition={{ duration: 0.5, delay: 0.35 }}
          initial="hidden"
          animate={mainControls}
          color="secondary"
          variant="h5"
          align="center"
          letterSpacing={1.6}
          fontFamily="Roboto"
          pb={10}
          pr={5}
          pl={5}
        >
          Effortlessly handle your design board through Jira.
          <br />
          <br />
          Seamlessly monitor active, queued, and completed tasks.
          <br />
          <br />
          Welcome an unlimited number of team members
          <br /> to submit requests and track progress collectively.
          <br />
          <br />
          Endless revisions until you're
          <br /> 100% satisfied.
          <br />
          <br />
        </TypographyDiv>
      </AnimateContainer>

      <Box
        sx={{
          width: "100%",
          color: "#EFF3F7",
          p: { xs: 5, md: 11 },
          pt: 16,
          pb: 16,
          fontFamily: "Roboto",
        }}
      >
        <Categories />
      </Box>

      <CostContainer ref={newRef}>
        <TypographyDiv
          whileInView={{ opacity: 1 }}
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          transition={{ duration: 0.5, delay: 0.25 }}
          initial="hidden"
          animate={SubControls}
          color="#EFF3F7"
          variant="h3"
          align="center"
          letterSpacing={1.6}
          fontFamily="Roboto"
          pb={3}
          pt={3}
        >
          Premium Access
        </TypographyDiv>

        <TypographyDiv
          whileInView={{ opacity: 1 }}
          variants={{
            hidden: { opacity: 0, y: 75 },
            visible: { opacity: 1, y: 0 },
          }}
          transition={{ duration: 0.5, delay: 0.25 }}
          initial="hidden"
          animate={SubControls}
          align="center"
        >
          <Typography className="lottieDiv" pb={5} align="center">
            <Lottie className="lottie" animationData={rocket} loop={true} />
          </Typography>
        </TypographyDiv>

          <TypographyDiv
            whileInView={{ opacity: 1 }}
            variants={{
              hidden: { opacity: 0, y: 75 },
              visible: { opacity: 1, y: 0 },
            }}
            transition={{ duration: 0.5, delay: 0.45 }}
            initial="hidden"
            animate={SubControls}
            color="secondary"
            variant="h5"
            align="left"
            letterSpacing={1.6}
            fontFamily="Roboto"
            pb={15}
            pt={10}
            sx={{ paddingLeft: { xs: 3, md: 10, lg: 20, xl: 60 }, paddingRight: { xs: 3, md: 10, lg: 20, xl: 60, }}}
          >
            Phlokk Design <b>revolutionizes the industry</b> by eliminating the need
            for unreliable freelancers and high-priced agencies. Experience the
            power of a single, flat monthly fee, while receiving <b>mind-blowing
            fast design</b> deliveries that will leave you in awe.
          </TypographyDiv>
      </CostContainer>

      <Box
        ref={pricing}
        sx={{
          width: "100%",
          color: "#EFF3F7",
          p: { xs: 5, md: 5 },
          pt: 16,
          pb: 16,
          fontFamily: "Roboto",
        }}
      >
        <Plans />
      </Box>

      <Box
        sx={{
          width: "100%",
          color: "primary",
          p: { xs: 5, md: 5 },
          pt: 16,
          pb: 16,
          fontFamily: "Roboto",
        }}
      >
        <Faq />
      </Box>

      <QuoteContainer>
        <Typography align="center" pt={2}>
          <img
            style={{ width: "250px" }}
            src={quotes}
            className="quotes"
            alt="quotes"
          />
        </Typography>
        <Typography
          color="secondary"
          variant="h5"
          align="center"
          letterSpacing={1.6}
          fontFamily="Roboto"
          pb={16}
          id="padding-title"
        >
          "It's the kind of 'once experienced, never forgotten' improvement that
          will forever change your expectations."
        </Typography>
      </QuoteContainer>
      <BoxContainer>
        <Typography
          color="secondary"
          variant="h3"
          align="center"
          letterSpacing={1.6}
          fontFamily="Roboto"
          pb={5}
          pt={10}
          pr={5}
          pl={5}
        >
          <b>Digital Designs</b> Exceptional Prices.
        </Typography>
        <Typography
          color="secondary"
          variant="h5"
          align="center"
          letterSpacing={1.6}
          fontFamily="Roboto"
          pb={5}
          pr={5}
          pl={5}
        >
          Embark on an immersive journey with Phlokk Design,
          <br /> revolutionizing your design sourcing experience.
        </Typography>
        <Typography align="center" pb={5} pt={2}>
          <img
            style={{ width: "20px" }}
            src={cta_pointer}
            className="quotes"
            alt="quotes"
          />
        </Typography>
        <CTAContainer>
          <div
            role="button"
            onClick={() => scrollToSection(pricing)}
            className="CTAButton"
          >
            <div>
              <b>GET STARTED</b>
            </div>
          </div>
        </CTAContainer>
      </BoxContainer>
    </>
  );
}

export default Home;
