import { Typography } from "@mui/material";
import React from "react";
import Navigation from "../Main_Nav";
import { LegalContainer } from "../styles/Container.Styled";
import ScrollToTop from "../../components/ScrollToTop";

function TermsAndConditions() {
  return (
    <>
      <Navigation />
      <LegalContainer>
      <ScrollToTop />
        <Typography
          color=  "#EFF3F7"
          letterSpacing={0.3}
          lineHeight={1.6}
          variant="body1"
          fontFamily="Roboto"
          gutterBottom
          m={3}
          pt={10}
          pr={2}
          pl={2}
          align="left"
        >
          <Typography
          letterSpacing={0.3}
          lineHeight={1.6}
          variant="h4"
          pb={5}
          pt={10}
          fontFamily="Roboto">Terms and Conditions
        </Typography>
          <b>Agreement between User and</b>{" "}
          <span className="termLinks">https://phlokkdesign.com</span>
          <br />
          <br />
          Welcome to <span className="termLinks">https://phlokkdesign.com</span>
          . The <span className="termLinks">https://phlokkdesign.com</span>{" "}
          website (the "Site") is comprised of various web pages operated by
          Phlokk Design.{" "}
          <span className="termLinks">https://phlokkdesign.com</span> is offered
          to you conditioned on your acceptance without modification of the
          terms, conditions, and notices contained herein (the "Terms"). Your
          use of <span className="termLinks">https://phlokkdesign.com</span>{" "}
          constitutes your agreement to all such Terms. Please read these terms
          carefully, and keep a copy of them for your reference.
          <br />
          <br />
          <span className="termLinks">https://phlokkdesign.com</span> is a News
          and Information Site.
          <br />
          <br />
          Phlokk Design's website will be used to inform internet goers the
          types of services it offers, such as custom software development and
          mobile app development, as well as clients and types of industries
          Phlokk Design has worked with in the past.
          <br />
          <br />
          <b>Electronic Communications</b>
          <br />
          <br />
          Visiting <span className="termLinks">
            https://phlokkdesign.com
          </span>{" "}
          or sending emails to Phlokk Design constitutes electronic
          communications. You consent to receive electronic communications and
          you agree that all agreements, notices, disclosures and other
          communications that we provide to you electronically, via email and on
          the Site, satisfy any legal requirement that such communications be in
          writing.
          <br />
          <br />
          <b>Children Under Thirteen</b>
          <br />
          <br />
          Phlokk Design does not knowingly collect, either online or offline,
          personal information from persons under the age of thirteen. If you
          are under 18, you may use{" "}
          <span className="termLinks">https://phlokkdesign.com</span> only with
          permission of a parent or guardian.
          <br />
          <br />
          <b>Links to Third Party Sites/Third Party Services</b>
          <br />
          <br />
          <span className="termLinks">https://phlokkdesign.com</span> may
          contain links to other websites ("Linked Sites"). The Linked Sites are
          not under the control of Phlokk Design and Phlokk Design is not
          responsible for the contents of any Linked Site, including without
          limitation any link contained in a Linked Site, or any changes or
          updates to a Linked Site. Phlokk Design is providing these links to
          you only as a convenience, and the inclusion of any link does not
          imply endorsement by Phlokk Design of the site or any association with
          its operators.
          <br />
          <br />
          Certain services made available via{" "}
          <span className="termLinks">https://phlokkdesign.com</span> are
          delivered by third party sites and organizations. By using any
          product, service or functionality originating from the{" "}
          <span className="termLinks">https://phlokkdesign.com</span> domain,
          you hereby acknowledge and consent that Phlokk Design may share such
          information and data with any third party with whom Phlokk Design has
          a contractual relationship to provide the requested product, service
          or functionality on behalf of{" "}
          <span className="termLinks">https://phlokkdesign.com</span> users and
          customers.
          <br />
          <br />
          <b>No Unlawful or Prohibited Use/Intellectual Property</b>
          <br />
          <br />
          You are granted a non-exclusive, non-transferable, revocable license
          to access and use{" "}
          <span className="termLinks">https://phlokkdesign.com</span> strictly
          in accordance with these terms of use. As a condition of your use of
          the Site, you warrant to Phlokk Design that you will not use the Site
          for any purpose that is unlawful or prohibited by these Terms. You may
          not use the Site in any manner which could damage, disable,
          overburden, or impair the Site or interfere with any other party's use
          and enjoyment of the Site. You may not obtain or attempt to obtain any
          materials or information through any means not intentionally made
          available or provided for through the Site.
          <br />
          <br />
          All content included as part of the Service, such as text, graphics,
          logos, images, as well as the compilation thereof, and any software
          used on the Site, is the property of Phlokk Design or its suppliers
          and protected by copyright and other laws that protect intellectual
          property and proprietary rights. You agree to observe and abide by all
          copyright and other proprietary notices, legends or other restrictions
          contained in any such content and will not make any changes thereto.
          <br />
          <br />
          You will not modify, publish, transmit, reverse engineer, participate
          in the transfer or sale, create derivative works, or in any way
          exploit any of the content, in whole or in part, found on the Site.
          Phlokk Design content is not for resale. Your use of the Site does not
          entitle you to make any unauthorized use of any protected content, and
          in particular you will not delete or alter any proprietary rights or
          attribution notices in any content. You will use protected content
          solely for your personal use, and will make no other use of the
          content without the express written permission of Phlokk Design and
          the copyright owner. You agree that you do not acquire any ownership
          rights in any protected content. We do not grant you any licenses,
          express or implied, to the intellectual property of Phlokk Design or
          our licensors except as expressly authorized by these Terms.
          <br />
          <br />
          <b>International Users</b>
          <br />
          <br />
          The Service is controlled, operated and administered by Phlokk Design
          from our offices within the USA. If you access the Service from a
          location outside the USA, you are responsible for compliance with all
          local laws. You agree that you will not use the Phlokk Design Content
          accessed through{" "}
          <span className="termLinks">https://phlokkdesign.com</span> in any
          country or in any manner prohibited by any applicable laws,
          restrictions or regulations.
          <br />
          <br />
          <b>Indemnification</b>
          <br />
          <br />
          You agree to indemnify, defend and hold harmless Phlokk Design, its
          officers, directors, employees, agents and third parties, for any
          losses, costs, liabilities and expenses (including reasonable
          attorney's fees) relating to or arising out of your use of or
          inability to use the Site or services, any user postings made by you,
          your violation of any terms of this Agreement or your violation of any
          rights of a third party, or your violation of any applicable laws,
          rules or regulations. Phlokk Design reserves the right, at its own
          cost, to assume the exclusive defense and control of any matter
          otherwise subject to indemnification by you, in which event you will
          fully cooperate with Phlokk Design in asserting any available
          defenses.
          <br />
          <br />
          <b>Arbitration</b>
          <br />
          <br />
          In the event the parties are not able to resolve any dispute between
          them arising out of or concerning these Terms and Conditions, or any
          provisions hereof, whether in contract, tort, or otherwise at law or
          in equity for damages or any other relief, then such dispute shall be
          resolved only by final and binding arbitration pursuant to the Federal
          Arbitration Act, conducted by a single neutral arbitrator and
          administered by the American Arbitration Association, or a similar
          arbitration service selected by the parties, in a location mutually
          agreed upon by the parties. The arbitrator's award shall be final, and
          judgment may be entered upon it in any court having jurisdiction. In
          the event that any legal or equitable action, proceeding or
          arbitration arises out of or concerns these Terms and Conditions, the
          prevailing party shall be entitled to recover its costs and reasonable
          attorney's fees. The parties agree to arbitrate all disputes and
          claims in regards to these Terms and Conditions or any disputes
          arising as a result of these Terms and Conditions, whether directly or
          indirectly, including Tort claims that are a result of these Terms and
          Conditions. The parties agree that the Federal Arbitration Act governs
          the interpretation and enforcement of this provision. The entire
          dispute, including the scope and enforceability of this arbitration
          provision shall be determined by the Arbitrator. This arbitration
          provision shall survive the termination of these Terms and Conditions.
          <br />
          <br />
          <b>Liability Disclaimer</b>
          <br />
          <br />
          THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR
          AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL
          ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN.
          PHLOKK DESIGN AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR
          CHANGES IN THE SITE AT ANY TIME.
          <br />
          <br />
          PHLOKK DESIGN AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE
          SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF
          THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS
          CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED
          BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES
          AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR
          CONDITION OF ANY KIND. PHLOKK DESIGN AND/OR ITS SUPPLIERS HEREBY
          DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS
          INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS,
          INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY,
          FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
          <br />
          <br />
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
          PHLOKK DESIGN AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT,
          PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES
          WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE,
          DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE
          OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE
          SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE
          SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND
          RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT
          OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE,
          STRICT LIABILITY OR OTHERWISE, EVEN IF PHLOKK DESIGN OR ANY OF ITS
          SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME
          STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
          LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE
          LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY
          PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND
          EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.
          <br />
          <br />
          <b>Termination/Access Restriction</b>
          <br />
          <br />
          Phlokk Design reserves the right, in its sole discretion, to terminate
          your access to the Site and the related services or any portion
          thereof at any time, without notice. To the maximum extent permitted
          by law, this agreement is governed by the laws of the State of
          Tennessee and you hereby consent to the exclusive jurisdiction and
          venue of courts in Tennessee in all disputes arising out of or
          relating to the use of the Site. Use of the Site is unauthorized in
          any jurisdiction that does not give effect to all provisions of these
          Terms, including, without limitation, this section.
          <br />
          <br />
          You agree that no joint venture, partnership, employment, or agency
          relationship exists between you and Phlokk Design as a result of this
          agreement or use of the Site. Phlokk Design's performance of this
          agreement is subject to existing laws and legal process, and nothing
          contained in this agreement is in derogation of Phlokk Design's right
          to comply with governmental, court and law enforcement requests or
          requirements relating to your use of the Site or information provided
          to or gathered by Phlokk Design with respect to such use. If any part
          of this agreement is determined to be invalid or unenforceable
          pursuant to applicable law including, but not limited to, the warranty
          disclaimers and liability limitations set forth above, then the
          invalid or unenforceable provision will be deemed superseded by a
          valid, enforceable provision that most closely matches the intent of
          the original provision and the remainder of the agreement shall
          continue in effect.
          <br />
          <br />
          Unless otherwise specified herein, this agreement constitutes the
          entire agreement between the user and Phlokk Design with respect to
          the Site and it supersedes all prior or contemporaneous communications
          and proposals, whether electronic, oral or written, between the user
          and Phlokk Design with respect to the Site. A printed version of this
          agreement and of any notice given in electronic form shall be
          admissible in judicial or administrative proceedings based upon or
          relating to this agreement to the same extent and subject to the same
          conditions as other business documents and records originally
          generated and maintained in printed form. It is the express wish to
          the parties that this agreement and all related documents be written
          in English.
          <br />
          <br />
          <b>Changes to Terms</b>
          <br />
          <br />
          Phlokk Design reserves the right, in its sole discretion, to change
          the Terms under which{" "}
          <span className="termLinks">https://phlokkdesign.com</span> is
          offered. The most current version of the Terms will supersede all
          previous versions. Phlokk Design encourages you to periodically review
          the Terms to stay informed of our updates.
          <br />
          <br />
          <b>Contact Us</b>
          <br />
          Phlokk Design welcomes your questions or comments regarding the Terms:
          <br />
          <br />
          Phlokk Design
          <br />
          Nashville, Tennessee
          <br />
          <br />
          Email Address:
          <br />
          legal@phlokk.com
          <br />
          <br />
          Telephone number:
          <br />
          615-967-5790
          <br />
        </Typography>
      </LegalContainer>
    </>
  );
}

export default TermsAndConditions;
