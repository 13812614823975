import { ThemeProvider } from "@mui/system";
import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Footer, Home, Services, Custom, Contact, Work, Software } from "./components";
import theme from "../src/theme"
import GlobalStyles from "./components/styles/Global"
import TermsAndConditions from "./components/documents/TermsAndConditions";
import PrivacyPolicy from "./components/documents/PrivacyPolicy";
import NotFound from "./components/NotFound";
import About from "./components/About";




const root = createRoot(document.getElementById("root"));


root.render(

  <Router>
<ThemeProvider theme={theme}>
  <GlobalStyles />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/services" element={<Services />} />
      <Route path="/work" element={<Work />} />
      <Route path="/software" element={<Software/>} />
      <Route path="/custom" element={<Custom />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/terms" element={<TermsAndConditions />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
    <Footer />
    </ThemeProvider>
  </Router>
);
