import React, { useState } from "react";
import {v4 as uuidv4 } from "uuid";
import { Grid, Typography, Box } from "@mui/material";
import Lottie from "lottie-react";
import { BoxContainer, CategoryContainer } from "./styles/Container.Styled";
import funk_star from "../images/funk_star.svg";
import canvas from "../components/lottie/canvas.json"

function Categories() {
  const [categories] = useState([
    { title: "Websites" },
    { title: "Mobile apps" },
    { title: "Logos & branding" },
    { title: "Stationery" },
    { title: "Pitch decks" },
    { title: "Slide decks" },
    { title: "Brochures" },
    { title: "Signage" },
    { title: "Brand guides" },
    { title: "Social Media" },
    { title: "Email graphics" },
    { title: "Business cards" },
    { title: "Blog graphics" },
    { title: "Infographics" },
    { title: "Digital ads" },
    { title: "EPKs" },
    { title: "T-Shirt Designs" },
  ]);
  return (
    <>
      <BoxContainer>
      <Typography className="lottieCanvas" pb={5} pt={10} align="center">
                <Lottie className="lottieCanvasJson" animationData={canvas} loop={true} />
              </Typography>
        <Typography
          color="secondary"
          variant="h3"
          align="center"
          letterSpacing={1.6}
          fontFamily="Roboto"
          pb={5}
          pr={5}
          pl={5}
        >
          <b>Digital solutions</b> for all platforms.
        </Typography>
        <Typography
          color="secondary"
          variant="h5"
          align="center"
          letterSpacing={1.6}
          fontFamily="Roboto"
          pb={5}
          pr={5}
          pl={5}
        >
          <b>Services offered in our membership</b>
        </Typography>
        <Typography align="center" pt={2}>
          <img style={{ width: "35px" }} src={funk_star} alt="funk star" />
        </Typography>
      </BoxContainer>
      <BoxContainer>
        <CategoryContainer>
          <Grid
            letterSpacing={1.7}
            container
            rowSpacing={2}
            columnSpacing={2}
          >
            {categories.map((category) => {
              return (
                <Grid item xs={12} sm={4}>
                  <Box
                    key={uuidv4()}
                    sx={{
                      borderBottomWidth: "1.5px",
                      borderBottomStyle: "solid",
                      borderBottomColor: "#333",
                    }}
                    pb={2}
                  >
                    {category.title}
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </CategoryContainer>
      </BoxContainer>
    </>
  );
}

export default Categories;
