import React from "react";
import { Grid, Typography, Button, Box, Divider} from "@mui/material";
import {
  BoxContainer,
  PricesContainer,
  TitleBox,
} from "./styles/Container.Styled";
import sqiggles from "../images/sqiggle.svg";
import Referrals from "./Referrals";

function Plans() {
  return (
    <>
      <Typography
        color="#EFF3F7"
        variant="h3"
        align="center"
        letterSpacing={1.6}
        fontFamily="Roboto"
        pb={3}
        pt={5}
      >
        <b>Membership levels</b>
      </Typography>
      <Typography
        color="#EFF3F7"
        variant="h4"
        align="center"
        letterSpacing={1.6}
        fontFamily="Roboto"
        pb={3}
        pt={5}
      >
        Choose a plan that fits <b>your needs</b>
      </Typography>
      
        <BoxContainer>
          <Typography align="center">
            <img style={{ width: "350px" }} src={sqiggles} alt="sqiggles" />
          </Typography>
        </BoxContainer>

        <BoxContainer>
        <PricesContainer>
          <Grid

            container
            rowSpacing={2}
            columnSpacing={2}
          >
            <Grid item xs={12} sm={4}>
              <TitleBox fontSize={24} p={2}>
                <b>Standard</b>
              </TitleBox>
              <Typography letterSpacing={1.7} fontFamily='OpenSans_Condensed-Regular' pt={3} fontSize={24}>
                <b>$4,995/m</b>
              </Typography>
              <Typography fontFamily='OpenSans_Condensed-Regular' sx={{ color: "#bdc3c7" }} letterSpacing={1.7} fontSize={12}>
                Not including applications
              </Typography>
              <Typography pt={3} fontFamily='OpenSans_Condensed-Regular' fontSize={18}>
                What's included:
              </Typography>
              <Typography fontFamily='OpenSans_Condensed-Regular' pt={3} fontSize={17}>
                One request at a time
              </Typography>
              <Typography fontFamily='OpenSans_Condensed-Regular' fontSize={17}>
                Average 48 hour delivery
              </Typography>
              <Typography fontFamily='OpenSans_Condensed-Regular' fontSize={17}>Unlimited brands</Typography>
              <Typography fontFamily='OpenSans_Condensed-Regular' fontSize={17}>
                Unlimited stock photos via iStock
              </Typography>
              <Typography fontFamily='OpenSans_Condensed-Regular' fontSize={17}>
                Simplified billing
              </Typography>
              <Typography fontFamily='OpenSans_Condensed-Regular' fontSize={17}>
                Pause or cancel anytime
              </Typography>
              <Typography pt={5} pb={10}>
                <Button
                  disableRipple
                  href="https://buy.stripe.com/aEU29T3dWfmtfp6fYY"
                  color="secondary"
                  target="_blank"
                  variant="outlined"
                  p={2}
                >
                  <b>Start Plan</b>
                </Button>
              </Typography>
            </Grid>


            <Grid item xs={12} sm={4}>
              <TitleBox fontSize={24} p={2}>
                <b>Pro</b>
              </TitleBox>
              <Typography pt={3} letterSpacing={1.7} fontFamily='OpenSans_Condensed-Regular' fontSize={24}>
                <b>$9,995/m</b>
              </Typography>
              <Typography sx={{ color: "#bdc3c7" }} letterSpacing={1.7} fontFamily='OpenSans_Condensed-Regular' fontSize={12}>
                For application development
              </Typography>
              <Typography pt={3} fontFamily='OpenSans_Condensed-Regular' fontSize={18}>
                What's included:
              </Typography>
              <Typography pt={3} fontFamily='OpenSans_Condensed-Regular' fontSize={17}>
                Server set up
              </Typography>
              <Typography fontFamily='OpenSans_Condensed-Regular' fontSize={17}>
                UI/UX development
              </Typography>
              <Typography fontFamily='OpenSans_Condensed-Regular' fontSize={17}>API development</Typography>
              <Typography fontFamily='OpenSans_Condensed-Regular' fontSize={17}>
                Database development
              </Typography>
              <Typography fontFamily='OpenSans_Condensed-Regular' fontSize={17}>
                Application submission
              </Typography>
              <Typography fontFamily='OpenSans_Condensed-Regular' fontSize={17}>
                Pause or cancel anytime
              </Typography>
              <Typography pt={5} pb={10}>
                <Button
                  disableRipple
                  href="https://buy.stripe.com/bIYaGp4i0gqxel2aEF"
                  color="secondary"
                  target="_blank"
                  variant="outlined"
                  p={2}
                >
                  <b>Start Plan</b>
                </Button>
              </Typography>
            </Grid>


            <Grid item xs={12} sm={4}>
              <TitleBox fontSize={24} p={2}>
                <b>Website</b>
              </TitleBox>
              <Typography pt={3} letterSpacing={1.7} fontFamily='OpenSans_Condensed-Regular' fontSize={24}>
                <b>Custom Design</b>
              </Typography>
              <Typography sx={{ color: "#bdc3c7" }} letterSpacing={1.7} fontFamily='OpenSans_Condensed-Regular' fontSize={12}>
                Includes SSL certificate
              </Typography>
              <Typography pt={3} fontFamily='OpenSans_Condensed-Regular' fontSize={18}>
                What's included:
              </Typography>
              <Typography pt={3} fontFamily='OpenSans_Condensed-Regular' fontSize={17}>
              Server & Domain Set Up
              </Typography>
              <Typography fontFamily='OpenSans_Condensed-Regular' fontSize={17}>
              Logo design
              </Typography>
              <Typography fontFamily='OpenSans_Condensed-Regular' fontSize={17}>
              SEO Optimization
              </Typography>
              <Typography fontFamily='OpenSans_Condensed-Regular' fontSize={17}>
              Average 5-7 day delivery
              </Typography>
              <Typography fontFamily='OpenSans_Condensed-Regular' fontSize={17}>
              Fully responsive
              </Typography>
              <Typography fontFamily='OpenSans_Condensed-Regular' fontSize={17}>
                One-time payment
              </Typography>
              <Typography pt={5} pb={10}>
                <Button
                  disableRipple
                  href="https://phlokkdesign.com/contact"
                  color="secondary"
                  target="_blank"
                  variant="outlined"
                  p={2}
                >
                  <b>Get a quote</b>
                </Button>
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{backgroundColor: "#fff"}}></Divider>
          <Box pt={3}>
        <Referrals />
        </Box>

        </PricesContainer>
        
        
      </BoxContainer>
    </>
  );
}

export default Plans;
