import { Box, Grid, List, ListItemText, Typography } from "@mui/material";
import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useNavigate, NavLink } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import logo from "../images/phlokk-design-logo.svg";

const firstGridLinks = [
  { title: "Services", link: "/services" },
  { title: "Work", link: "/work" },
  { title: "Software", link: "/software" },
  { title: "Custom", link: "/custom" },
  { title: "About", link: "/about" },
  { title: "Contact", link: "/contact" },
];
const secondGridLinks = [
  { title: "Terms and Conditions", link: "/terms" },
  { title: "Privacy Policy", link: "/privacy-policy" },
];

const mobile = (
  <a href="tel:6159675790" aria-label="Call Phlokk Design">
    (615) 967 7890
  </a>
);

const thirdGridLinks = [
  { title: "phlokkdesign@phlokk.com" },
  { title: "(615) 967 7890", href: mobile },
];

function Footer() {
  const matches = useMediaQuery("(max-width:900px)");
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          marginTop: "auto",
          background: "#000",
          color: "#EFF3F7",
          p: { xs: 4, md: 10 },
          pt: 12,
          pb: 12,
          fontSize: { xs: "12px", md: "14px" },
          marginLeft: "auto",
          marginRight: "auto",
          position: "relative",
        }}
      >
        <Grid container spacing={2} justifyContent="center">
          <Grid
            sx={
              matches
                ? {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: "15px",
                    textAlign: "center",
                  }
                : {}
            }
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
          >
            <NavLink className="navbar-brand" to="/">
              <img
                style={{ width: "300px" }}
                src={logo}
                className="site-logo-footer"
                alt="logo"
              />
            </NavLink>
            <Box
              sx={{
                mt: matches ? 0 : 4,
                color: "#EFF3F7",
                pb: 3,
              }}
            >
              <a aria-label="facebook" href="https://www.facebook.com/phlokk">
                <FacebookIcon sx={{ mr: 2, color: "#fff" }} />
              </a>
              <a
                aria-label="instagram"
                href="https://www.instagram.com/phlokk_design"
              >
                <InstagramIcon sx={{ mr: 2, color: "#fff" }} />
              </a>
            </Box>
            <Typography
          variant="body1"
          align="left"
          fontFamily="OpenSans_Condensed-Regular"
        >
          © {new Date().getFullYear()} Phlokk LLC
        </Typography>
          </Grid>

          <Grid
            sx={
              matches
                ? {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: "10px",
                    textAlign: "center",
                  }
                : {}
            }
            item
            xs={12}
            sm={12}
            md={6}
            lg={2}
          >
            <Typography
              variant="h6"
              letterSpacing={1.7}
              fontFamily="OpenSans_Condensed-Regular"
              sx={{ textTransform: "uppercase", fontWeight: "bold" }}
            >
              Quick Nav
            </Typography>
            <List>
              <ListItemText>
                <div
                  aria-label="footer links"
                  role="contentinfo"
                  className="footerLinkText"
                >
                  {firstGridLinks?.map((linkItem) => (
                    <div
                      key={linkItem}
                      onClick={() => navigate(linkItem?.link)}
                    >
                      <Typography
                        sx={{
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                        letterSpacing={1.7}
                        fontFamily="OpenSans_Condensed-Regular"
                        paddingTop="5px"
                      >
                        {linkItem.title}{" "}
                      </Typography>
                    </div>
                  ))}
                </div>
              </ListItemText>
            </List>
          </Grid>

          <Grid
            sx={
              matches
                ? {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: "10px",
                    textAlign: "center",
                  }
                : {}
            }
            xs={12}
            sm={12}
            item
            md={6}
            lg={2}
          >
            <Typography
              variant="h6"
              letterSpacing={1.7}
              fontFamily="OpenSans_Condensed-Regular"
              sx={{ textTransform: "uppercase", fontWeight: "bold" }}
            >
              Legal
            </Typography>

            <List>
              <ListItemText>
                <div className="footerLinkText">
                  {secondGridLinks?.map((linkItem) => (
                    <div
                      key={linkItem}
                      onClick={() => navigate(linkItem?.link)}
                    >
                      <Typography
                        sx={{
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                        letterSpacing={1.7}
                        fontFamily="OpenSans_Condensed-Regular"
                        paddingTop="5px"
                        // pr={2}
                      >
                        {linkItem.title}{" "}
                      </Typography>
                    </div>
                  ))}
                </div>
              </ListItemText>
            </List>
          </Grid>

          <Grid
            sx={
              matches
                ? {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: "10px",
                    textAlign: "center",
                  }
                : {}
            }
            xs={12}
            sm={12}
            item
            md={6}
            lg={2}
          >
            <Typography
              variant="h6"
              letterSpacing={1.7}
              fontFamily="OpenSans_Condensed-Regular"
              sx={{ textTransform: "uppercase", fontWeight: "bold" }}
            >
              Contact
            </Typography>

            <List>
              <ListItemText>
                <div className="footerLinkText">
                  {thirdGridLinks?.map((linkItem) => (
                    <div
                      key={linkItem}
                      onClick={() => navigate(linkItem?.href)}
                    >
                      <Typography
                        sx={{
                          "&:hover": {
                            cursor: "pointer",
                          },
                        }}
                        letterSpacing={1.7}
                        fontFamily="OpenSans_Condensed-Regular"
                        paddingTop="5px"
                      >
                        {linkItem.title}{" "}
                      </Typography>
                    </div>
                  ))}
                </div>
              </ListItemText>
            </List>
          </Grid>
        </Grid>
        
      </Box>
    </>
  );
}

export default Footer;
