import styled from "styled-components";
import bg3 from "../../images/bg3.png";

export const HeroContainer = styled.div`
  background-image: url(${bg3});
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;

  width: 100vw;
  color: #eff3f7;
  max-width: 100%;
  padding-bottom: 35px;
`;

export const SalesContainer = styled.div`
  max-width: 1240px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  color: #eff3f7;

  margin-top: 50px;
`;

export const AnimateContainer = styled.div`
  max-width: 1240px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;
// AAC4FF

export const QuoteContainer = styled.div`
  max-width: 1240px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  color: #eff3f7;
  margin-top: 50px;
`;

export const CostContainer = styled.div`
  background-color: #080066;
  padding-top: 40px;
  padding-bottom: 10px;
  margin-top: 150px;
  font-size: 17px;
  font-weight: 700;
`;

export const CategoryContainer = styled.div`
  text-align: left;
  margin-top: 100px;
  padding: 50px;
  font-size: 18px;
  font-family: 'OpenSans_Condensed-Regular';
`;

export const PricesContainer = styled.div`
  border-radius: 25px;
  border-width: 2px;
  border-style: solid;
  border-color: #080066;
  text-align: center;
  margin-top: 100px;
  padding: 50px;
  font-size: 20px;
  font-weight: 700;
  background-color: #080066;
`;

export const FaqContainer = styled.div`
  text-align: left;
  margin-top: 100px;
  font-size: 20px;
  font-weight: 700;
  position: relative;
`;

export const BoxContainer = styled.div`
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;

export const WebContainer = styled.div`
  padding-top: 100px;
  max-width: 1240px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;

export const PlansContainer = styled.div`
  max-width: 250px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;

export const CTAContainer = styled.div`
  max-width: 250px;
  padding-bottom: 100px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;

export const TabsContainer = styled.div`
  max-width: 1240px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 100px;
`;


export const ExperienceContainer = styled.div`
  background-color: #080066;
  max-width: 100%;
  padding-bottom: 100px;
`;

export const TitleBox = styled.div`
  letter-spacing: 0.5px;
  font-size: 24px;
  font-weight: 200;
  line-height: 30px;
`;

export const LegalContainer = styled.div`
  max-width: 1240px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const BlackBoxContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
`;


export const ButtonContainer = styled.div`
  width: 75%;
  height: auto;
  display: flex;
`;


export const PromotionsContainer = styled.div`
  background-color: rgba(210, 218, 255, 0.2);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1b1464;
`;

